/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { sylvesterDependencies } from './dependenciesSylvester.generated.js';
import { transposeDependencies } from './dependenciesTranspose.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLyap } from '../../factoriesAny.js';
export var lyapDependencies = {
  matrixDependencies,
  multiplyDependencies,
  sylvesterDependencies,
  transposeDependencies,
  typedDependencies,
  createLyap
};