/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ImmutableDenseMatrixDependencies } from './dependenciesImmutableDenseMatrixClass.generated.js';
import { getMatrixDataTypeDependencies } from './dependenciesGetMatrixDataType.generated.js';
import { createIndexClass } from '../../factoriesAny.js';
export var IndexDependencies = {
  ImmutableDenseMatrixDependencies,
  getMatrixDataTypeDependencies,
  createIndexClass
};