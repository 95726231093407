/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { IndexDependencies } from './dependenciesIndexClass.generated.js';
import { getMatrixDataTypeDependencies } from './dependenciesGetMatrixDataType.generated.js';
import { createIndexTransform } from '../../factoriesAny.js';
export var indexTransformDependencies = {
  IndexDependencies,
  getMatrixDataTypeDependencies,
  createIndexTransform
};