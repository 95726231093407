/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addScalarDependencies } from './dependenciesAddScalar.generated.js';
import { ceilDependencies } from './dependenciesCeil.generated.js';
import { conjDependencies } from './dependenciesConj.generated.js';
import { divideScalarDependencies } from './dependenciesDivideScalar.generated.js';
import { dotDivideDependencies } from './dependenciesDotDivide.generated.js';
import { expDependencies } from './dependenciesExp.generated.js';
import { iDependencies } from './dependenciesI.generated.js';
import { log2Dependencies } from './dependenciesLog2.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyScalarDependencies } from './dependenciesMultiplyScalar.generated.js';
import { powDependencies } from './dependenciesPow.generated.js';
import { tauDependencies } from './dependenciesTau.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createFft } from '../../factoriesAny.js';
export var fftDependencies = {
  addScalarDependencies,
  ceilDependencies,
  conjDependencies,
  divideScalarDependencies,
  dotDivideDependencies,
  expDependencies,
  iDependencies,
  log2Dependencies,
  matrixDependencies,
  multiplyScalarDependencies,
  powDependencies,
  tauDependencies,
  typedDependencies,
  createFft
};