/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { addDependencies } from './dependenciesAdd.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { numberDependencies } from './dependenciesNumber.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createZpk2tf } from '../../factoriesAny.js';
export var zpk2tfDependencies = {
  ComplexDependencies,
  addDependencies,
  multiplyDependencies,
  numberDependencies,
  typedDependencies,
  createZpk2tf
};