/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { addDependencies } from './dependenciesAdd.generated.js';
import { divideDependencies } from './dependenciesDivide.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createFreqz } from '../../factoriesAny.js';
export var freqzDependencies = {
  ComplexDependencies,
  addDependencies,
  divideDependencies,
  matrixDependencies,
  multiplyDependencies,
  typedDependencies,
  createFreqz
};